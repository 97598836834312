/* Default value causes the scrollbar to blink, as the width and height of below component keeps changing rapidly for some reason.
 The component's height still changes after the fix below, but it's not visible in any way unless you look at HTML */
.ReactTable .rt-tbody {
    overflow: initial !important
  }

.pos:hover {
  text-decoration: underline;
}

.-header {
    position: sticky;
    top: 0;
    background: white
}

.-filters {
    position: sticky;
    top: 28px;
    background: white
}

.chr-label {
    cursor: pointer;
}

.chr-selection {
  cursor: pointer;
}

.react-tabs__tab--disabled {
    color: GrayText;
    cursor: not-allowed;
}

/* filter actions */
#filterActions_grow {
  margin-top: 8px;
  margin-bottom: 8px;
  -moz-transition: height .5s;
  -ms-transition: height .5s;
  -o-transition: height .5s;
  -webkit-transition: height .5s;
  transition: height .5s;
  height: 0;
  overflow: hidden;
}

.fillAvailableBtns {
  width: -webkit-fill-available;
}

/* column filtering */
#columnFiltersDiv_grow {
  /* margin-top: 8px; */
  -moz-transition: height .5s;
  -ms-transition: height .5s;
  -o-transition: height .5s;
  -webkit-transition: height .5s;
  transition: height .5s;
  height: 0;
  overflow: hidden;
}

.filterByOptions .select__control {
  min-height: 30px;
}

.sampleNotesDiv_grow {
  /* margin: 8px; */
  -moz-transition: height .5s;
  -ms-transition: height .5s;
  -o-transition: height .5s;
  -webkit-transition: height .5s;
  transition: height .5s;
  height: 0;
  overflow: hidden;
  width: 100%;
}

.sampleInfoDiv_grow {
  /* margin: 8px; */
  -moz-transition: height .5s;
  -ms-transition: height .5s;
  -o-transition: height .5s;
  -webkit-transition: height .5s;
  transition: height .5s;
  height: 0;
  overflow: hidden;
  width: 100%;
}

.sampleNotesArea {
  margin: 10px;
  height: 100px;
  width: 100%; 
}

.exportReactCSVbtn{
  position: relative;
  float: right;
}

.exportReactCSVlink {
  color: #ffffff;
}

.tabButtonsRow {
  margin-bottom: 8px;
}

.columnFiltersDiv_content {
    column-count: 6;
    padding: 5px;
    background: rgb(241,242,245);
    background: -moz-linear-gradient(0deg, rgba(241,242,245,1) 0%, rgba(255,255,255) 100%);
    background: -webkit-linear-gradient(0deg, rgba(241,242,245,1) 0%, rgba(255,255,255) 100%);
    background: linear-gradient(0deg, rgba(241,242,245,1) 0%, rgba(255,255,255) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f1f2f5",endColorstr="#e2e2e2",GradientType=1);
    box-shadow: 0px 0px 5px 5px #ffffff;
    margin-top: 5px;
}

/* loading spinner */
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fff;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}

.rt-th:not(.rt-resizable-header) {
    overflow: visible !important
  }

  .select__indicators {
      display: none !important
  }


  /*.ReactVirtualized__List {
    overflow: overlay !important
  }
  .ReactTable .rt-th, .ReactTable .rt-td {
    overflow: hidden;
    text-overflow: ellipsis;
  }*/